@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

$palette-accent: #4299e1;
$palette-danger: #be1e2d;
$palette-dark: #1a1423;
$palette-info: #004777;
$palette-light: #fcfafa;
$palette-primary: #004777;
$palette-success: #4d8b31;
$palette-warn: #ec9f05;

$dynamic-theme-primary: (
  50: var(--theme-primary-50),
  100: var(--theme-primary-100),
  200: var(--theme-primary-200),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700)
  )
);

$dynamic-theme-accent: (
  50: var(--theme-accent-50),
  100: var(--theme-accent-100),
  200: var(--theme-accent-200),
  300: var(--theme-accent-300),
  400: var(--theme-accent-400),
  500: var(--theme-accent-500),
  600: var(--theme-accent-600),
  700: var(--theme-accent-700),
  800: var(--theme-accent-800),
  900: var(--theme-accent-900),
  A100: var(--theme-accent-A100),
  A200: var(--theme-accent-A200),
  A400: var(--theme-accent-A400),
  A700: var(--theme-accent-A700),
  contrast: (
    50: var(--theme-accent-contrast-50),
    100: var(--theme-accent-contrast-100),
    200: var(--theme-accent-contrast-200),
    300: var(--theme-accent-contrast-300),
    400: var(--theme-accent-contrast-400),
    500: var(--theme-accent-contrast-500),
    600: var(--theme-accent-contrast-600),
    700: var(--theme-accent-contrast-700),
    800: var(--theme-accent-contrast-800),
    900: var(--theme-accent-contrast-900),
    A100: var(--theme-accent-contrast-A100),
    A200: var(--theme-accent-contrast-A200),
    A400: var(--theme-accent-contrast-A400),
    A700: var(--theme-accent-contrast-A700)
  )
);

$dynamic-theme-danger: (
  50: var(--theme-danger-50),
  100: var(--theme-danger-100),
  200: var(--theme-danger-200),
  300: var(--theme-danger-300),
  400: var(--theme-danger-400),
  500: var(--theme-danger-500),
  600: var(--theme-danger-600),
  700: var(--theme-danger-700),
  800: var(--theme-danger-800),
  900: var(--theme-danger-900),
  A100: var(--theme-danger-A100),
  A200: var(--theme-danger-A200),
  A400: var(--theme-danger-A400),
  A700: var(--theme-danger-A700),
  contrast: (
    50: var(--theme-danger-contrast-50),
    100: var(--theme-danger-contrast-100),
    200: var(--theme-danger-contrast-200),
    300: var(--theme-danger-contrast-300),
    400: var(--theme-danger-contrast-400),
    500: var(--theme-danger-contrast-500),
    600: var(--theme-danger-contrast-600),
    700: var(--theme-danger-contrast-700),
    800: var(--theme-danger-contrast-800),
    900: var(--theme-danger-contrast-900),
    A100: var(--theme-danger-contrast-A100),
    A200: var(--theme-danger-contrast-A200),
    A400: var(--theme-danger-contrast-A400),
    A700: var(--theme-danger-contrast-A700)
  )
);

$dynamic-typography: mat.m2-define-typography-config(
  $font-family: 'San Francisco Display',
  $headline-1: mat.m2-define-typography-level(24px, 32px),
  $headline-2: mat.m2-define-typography-level(22px, 26px),
  $headline-3: mat.m2-define-typography-level(20px, 24px),
  $headline-4: mat.m2-define-typography-level(18px, 22px),
  $headline-5: mat.m2-define-typography-level(16px, 20px),
  $headline-6: mat.m2-define-typography-level(14px, 18px),
  $subtitle-1: mat.m2-define-typography-level(16px, 20px),
  $subtitle-2: mat.m2-define-typography-level(14px, 18px, 500),
  $body-1: mat.m2-define-typography-level(14px, 18px),
  $body-2: mat.m2-define-typography-level(16px, 20px),
  $caption: mat.m2-define-typography-level(12px, 14px),
  $button: mat.m2-define-typography-level(14px, 18px, 500),
  $overline: mat.m2-define-typography-level(14px, 18px)
);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette($dynamic-theme-primary),
      accent: mat.m2-define-palette($dynamic-theme-accent),
      warn: mat.m2-define-palette($dynamic-theme-danger)
    ),
    typography: $dynamic-typography,
    density: 0
  )
);

@include mat.all-component-themes($light-theme);

body {
  --palette-primary: var(--theme-primary-500, #{$palette-primary});
  --palette-accent: var(--theme-accent-500, #{$palette-accent});
  --palette-warn: var(--theme-warn-500, #{$palette-warn});
  --palette-success: var(--theme-success-500, #{$palette-success});
  --palette-danger: var(--theme-danger-500, #{$palette-danger});
  --palette-info: var(--theme-info-500, #{$palette-info});
  --palette-dark: var(--theme-dark-500, #{$palette-dark});
  --palette-light: var(--theme-light-500, #{$palette-light});
}
