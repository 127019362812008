[hidden] {
  display: none !important;
}

button {
  @apply focus:tw-outline-none;
}

.tw-color-danger {
  color: var(--palette-danger);
}

.tw-bg-primary {
  background-color: var(--palette-primary);
}

.mat-mdc-raised-button:not(:disabled) {
  &.tw-bg-primary {
    background-color: var(--palette-primary);
  }
  &.tw-bg-success {
    background-color: var(--palette-success);
  }
  &.tw-bg-accent {
    background-color: var(--palette-accent);
  }
  &.tw-bg-danger {
    background-color: var(--palette-danger);
  }
  &.tw-bg-warn {
    background-color: var(--palette-warn);
  }
  &.tw-bg-info {
    background-color: var(--palette-info);
  }

  &.tw-color-primary {
    color: var(--palette-primary);
  }
  &.tw-color-success {
    color: var(--palette-success);
  }
  &.tw-color-accent {
    color: var(--palette-accent);
  }
  &.tw-color-danger {
    color: var(--palette-danger);
  }
  &.tw-color-warn {
    color: var(--palette-warn);
  }
  &.tw-color-info {
    color: var(--palette-info);
  }
}
